<template>
  <div>
    <!-- 轮播 -->
    <div class="lunb">
      <div class="lunbItem">
        <img :src="img" alt="" width="65%" height="80%" style="margin: 4% 2%">
        <div class="lunbItemRight">
          <div class="rightItem" v-for="(item, index) in imgList" :key="item.id" @click="handclick(index, item)"
            :class="{ 'bk': imgls == index }">
            <img :src="item.consultBarUrl" alt="" width="100%">
          </div>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="conT">
      <!-- <div class="tcan resouceAndconsultFz">
        <span>超值</span><span>套餐</span><span>PACKAGE</span>
      </div> -->
      <!-- <div class="thCon">
        <div class="thConItem" v-for="(item, index) in thConItem" :key="index" @click="godetail(1, item)">
          <div class="jies">
            <img :src="item.consultsImageUrl" alt="" width="30%">
            <div class="jieCon">
              <div>{{ item.consultsTitle }}</div>
              <div class="contr">{{ item.consultsTitles }}</div>
              <div>￥{{ item.price }}
                </div>
            </div>
          </div>
          <hr>
          <div class="footDiv">
            <div>已有<span>{{ item.shopNum }}</span>人购买</div>
            <div>了解一下</div>
          </div>
        </div>
      </div> -->
      <div class="tcan resouceAndconsultFz" style="margin-top: 5%;">
        <span>热门</span><span>导师</span><span>TEATHER</span>
      </div>
      <!-- 老师列表 -->
      <div class="tcthersFlex">

        <div class="teacherBoxItem" @click="godetail(2, item)" v-for="(item, index) in teList" :key="index">
          <div class="teacherBoxItem_top">
            <div class="teacherBoxItem_topLeft">
              <img class="teacherUrl" :src="item.headSculptureUrl" alt="">
              <p class="teacherName">{{ item.teacherName }}</p>
            </div>
            <div class="teacherBoxItem_topRight">
              <span>{{ item.teacherDetail }}</span>
            </div>
          </div>
          <div class="teacherBoxItem_bottom">
            <p>已有 <span style="color: #74d19d">{{ item.serveNum }}</span>人购买</p>
            <span class="teacherBtn" @click="godetail(2, item)">详情</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
name: 'service';
import { getbannerList, getConsultList, getTeacherList } from '@/api/resourceAndconsult.js';
export default {

  data() {
    return {
      imgls: 0,
      imgList: [],
      img: '',
      thConItem: [],
      teList: [],



    };
  },
  methods: {
    // handclick(index, item) {
    handclick(index, item) {
      this.imgls = index;
      // 这里切换主轮播 img 可以写主轮播路径或者调试 成后端返回img
      this.img = item.consultBarUrl

    },
    godetail(type, item) {
      if (type == 1) {
        this.$router.push({
          path: '/serviceItem',
          query: {
            consultsId: item.consultsId
          }
        })
      } else if (type == 2) {
        this.$router.push({
          path: '/serviceItem2',
          query: {
            teacherId: item.teacherId
          }
        })
      }

    },
  },
  created() {
    // 轮播图
    getbannerList().then(res => {

      if (res.code == 200) {
        this.imgList = res.rows.map(item => {
          return {
            ...item,
            consultBarUrl: process.env.VUE_APP_BASE_API + item.consultBarUrl
          }
        })
        this.img = this.imgList[0].consultBarUrl
      }
    })
    // ====轮播结束====

    //  超值套餐 api
    getConsultList().then(res => {

      if (res.code == 200) {
        this.thConItem = res.rows.map(item => {
          return {
            ...item,
            consultsImageUrl: process.env.VUE_APP_BASE_API + item.consultsImageUrl
          }
        })
      }
    })
    // ===套餐结束

    // 热门导师api
    getTeacherList().then(res => {

      this.teList = res.data.map(item => {
        return {
          ...item,
          headSculptureUrl: process.env.VUE_APP_BASE_API + item.headSculptureUrl
        }
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.contr {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.lunb {
  width: 100%;
  height: 500px;
  background-image: url(../../assets/imgs/_背景\ 拷贝@2x.png);
  background-size: 100% 100%;
  margin-top: -10px;
  padding-top: 15px;
}

.lunbItem {
  // width: 75%;
  width: 1140px;
  height: 90%;
  // border: 1px solid #fff;
  margin: auto;
  position: relative;
}

.lunbItemRight {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 80%;
  float: right;
  margin-top: 4%;
}

.rightItem {
  width: 80%;
  // height: 100px;
  padding: 2% 2% 0 7%;
  margin: 5px;
}

.bk {
  background: url(../../assets/imgs/bk.png);
  background-size: 100% 100%;
}

.conT {
  // width: 72%;
  width: 1103px;
  margin: auto;
  // border: 1px solid #000;
  padding: 5px;
  // margin-top: 3%;
}

.tcan span:nth-child(1) {

  font-family: PingFang SC;
  font-weight: 500;
  color: #121212;
  border-bottom: 5px solid #74d19d;
}

.tcan span:nth-child(2) {

  font-family: PingFang SC;
  font-weight: 500;
  color: #121212;
}

.tcan span:nth-child(3) {
  font-size: 14px;
  font-family: "微软雅黑";
  font-weight: 500;
  color: #d4d4d4;
  margin-left: 10px;
  letter-spacing: 3px;
}

.thCon {
  margin-top: 4%;
}

.ons {
  float: right;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #a8a8a8;
  margin-right: 5%;
  cursor: pointer;
}

.thConItem {
  width: 28%;
  // height: 191px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 1px rgba(157, 157, 157, 0.5);
  border-radius: 5px;
  margin-top: 20px;
  margin: 1%;

  img {
    width: 100px;
    height: 77px;
  }
}

.jies {
  display: flex;
  position: relative;
}

.jies img {
  margin-top: 8%;
  margin-left: 5%;
}

.jieCon {
  margin-top: 8%;
  margin-left: 5%;
}

.jieCon div {
  margin-top: 5%;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1b1b1b;
  cursor: pointer;
}

.jieCon div:nth-child(2) {
  font-size: 14px;
  color: #c6c6c6;
}

.jieCon div:nth-child(3) {
  color: #fd8a23;
}

.jieCon span {
  font-size: 10px;
  border: 1px solid #fd8a23;
  border-radius: 10px;
  padding: 2px 10px;
  margin-left: 10px;
}

.thConItem hr {
  height: 1px;
  background-color: #c6c6c6;
  border: none;
  margin-top: 8%;
}

.footDiv div:nth-child(1) {
  float: left;
  width: 35%;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #a8a8a8;
  margin-left: 15px;
  margin-top: 2%;
}

.footDiv span {
  color: #fd8a23;
}

.footDiv div:nth-child(2) {
  float: right;
  width: 35%;
  height: 30px;
  background: #30c9b2;
  color: #ffffff;
  line-height: 30px;
  border-radius: 30px;
  text-align: center;
  margin-right: 8px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  cursor: pointer;
}

.thCon {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
}

.tcthersFlex {
  // display: flex;
  flex-wrap: wrap;
  margin-top: 5%;

}

.teacherBoxItem {
  // box-shadow: 0 0 3px #a6a6a6;
  box-shadow: 0px 0px 10px 1px rgba(157, 157, 157, 0.5);
  padding: 10px 20px;
  margin-bottom: 15px;
  box-sizing: border-box;
  cursor: default;
  display: flex;

  .teacherBoxItem_top {
    display: flex;
    flex: 8;
  }

  .teacherBoxItem_topLeft {
    width: 195px;
  }

  .teacherUrl {
    width: 100%;
  }

  .teacherName {
    text-align: center;

  }

  .teacherBoxItem_topRight {
    flex: 1;
    box-sizing: border-box;
    padding: 15px 25px;
    text-align: justify
  }

  .teacherBoxItem_bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    padding-top: 10px;
  }

  .teacherBtn {
    width: 120px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
    background: #30c9b2;
    color: white;
    border-radius: 15px;
    display: inline-block;
  }
}

.tcthers {
  width: 28%;
  // height: 349px;
  padding: 15px 0px;
  margin: 2%;
  box-shadow: 0px 0px 10px 1px rgba(157, 157, 157, 0.5);
  border-radius: 5px;
  padding-top: 5%;

  .jianj {
    width: 100%;
    height: 38px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* 设置显示的行数 */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.rcthersItem {
  position: relative;
  width: 60%;
  height: 60%;
  margin: auto;
  // border: 1px solid #000;
  margin-top: 5%;
}

.rcthersItem img {
  position: relative;
  display: block;
  width: 60%;
  height: 50%;
  margin: auto;
  z-index: 5;
}

.tig {
  position: relative;
  width: 60%;
  height: 36px;
  background-color: #ffce63;
  line-height: 36px;
  text-align: center;
  border-radius: 20px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: auto;
  margin-top: -15px;
  z-index: 10;
}

.rcthersItem div {
  text-align: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1b1b1b;
}

.rcthersItem div:nth-child(3) {
  margin-top: 10%;
}

.rcthersItem div:nth-child(4) {
  font-size: 14px;
  color: #a8a8a8;
  margin-top: 3%;
}

.rcthersItem div:nth-child(5) {
  padding: 5px;
  margin: auto;
  width: 100%;
  color: #30c9b2;
  border: 1px solid #30c9b2;
  border-radius: 20px;
  margin-top: 30%;
  cursor: pointer;
}
</style>
